// Модули
import { Common } from './modules/common.js'
import { Sliders } from './modules/sliders.js'
import { Modals } from './modules/modals.js'
import { Forms } from './modules/forms.js'
import { WindowScroll } from './modules/window-scroll.js'
import { WindowResize } from './modules/window-resize.js'

;(function ($) {
	$(document).ready(function () {
		Common(jQuery)
		Sliders(jQuery)
		Modals(jQuery)
		Forms(jQuery)
	})

	WindowScroll(jQuery)
	WindowResize(jQuery)
})(jQuery)

const changeColorBtn = () => {
	const btn = document.querySelector('.booking .btn')

	if (!btn) {
		return
	}

	if (window.innerWidth <= 1140) {
		btn.classList.remove('btn-primary')
		btn.classList.add('btn-white')
	}

	if (window.innerWidth > 1140) {
		btn.classList.add('btn-primary')
		btn.classList.remove('btn-white')
	}
}

// window.addEventListener('load', changeColorBtn)

// window.addEventListener('resize', changeColorBtn)



;(() => {
	try {
		const menuTitle = document.querySelectorAll('.menu__row-title')

		if (menuTitle) {
			menuTitle.forEach((item) => {
				item.addEventListener('click', () => {
					item.closest('.menu__row').classList.toggle('menu__row_open')
				})
			})
		}
	} catch (err) {
		console.log(err)
	}
})()
;(() => {
	try {
		const btn = document.querySelector('.tags-block-button')

		if (btn) {
			const changeClass = () => {
				const width = window.innerWidth

				if (width <= 840) {
					btn.classList.remove('tags-block-button')
					btn.classList.add('btn', 'btn-white')
				}

				if (width > 840) {
					btn.classList.remove('btn', 'btn-white')
					btn.classList.add('tags-block-button')
				}
			}

			window.addEventListener('DOMContentLoaded', changeClass)
			window.addEventListener('resize', changeClass)
		}
	} catch (err) {
		console.log(err)
	}
})();





window.onload = () => {
	setTimeout(() => {
		if (document.querySelector('.home-section-intro #block-search') !== null) {
			let iframeName = document.querySelector('.home-section-intro #block-search iframe'),
				iframeContent = iframeName.contentDocument;
		
			let newStyle = '<style type="text/css">';
				newStyle += '.sfl-container > .sf-wrapper { padding:0 !important; }';
				newStyle += '.sfl-container .sf-backlink-container { left:12px!important;bottom:1px!important; }';
				newStyle += '.sfl-container .sf-controls-wrapper { gap:20px; flex-wrap:wrap; flex-direction:row; }';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-field-container { flex:auto; max-width:calc(50% - 10px); margin:0; min-width:initial; }';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-extra-fields-container { flex:auto; max-width: calc(50% - 10px); margin:0; min-width:initial; }';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-extra-fields-container .sf-field-container { max-width:100%; }';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-submit-button-container { max-width: calc(50% - 10px); margin:0; }';
				newStyle += '@media only screen and (max-width: 480px ) {';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-submit-button-container .sfl-submit-button { min-width:initial; }'
				newStyle += '}';
				newStyle += '</style>';

			iframeContent.head.innerHTML += newStyle;

			document.querySelector('.home-section-intro .booking-block').classList.remove('is-hidden-search');
		}
		if (document.querySelector('.recreations-section-intro #block-search') !== null) {
			let iframeName = document.querySelector('.recreations-section-intro #block-search iframe');
			if(iframeName) {
				let	iframeContent = iframeName.contentDocument;

				let newStyle = '<style type="text/css">';
				newStyle += '.sfl-container > .sf-wrapper { padding:0 !important; }';
				newStyle += '.sfl-container .sf-backlink-container { left:12px!important;bottom:1px!important; }';
				newStyle += '.sfl-container .sf-controls-wrapper { gap:20px; flex-wrap:wrap; flex-direction:row; }';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-field-container { flex:auto; max-width:calc(50% - 10px); margin:0; min-width:initial; }';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-extra-fields-container { flex:auto; max-width: calc(50% - 10px); margin:0; min-width:initial; }';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-extra-fields-container .sf-field-container { max-width:100%; }';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-submit-button-container { max-width: calc(50% - 10px); margin:0; }';
				newStyle += '@media only screen and (max-width: 480px ) {';
				newStyle += '.sfl-container .sf-controls-wrapper .sf-submit-button-container .sfl-submit-button { min-width:initial; }'
				newStyle += '}';
				newStyle += '</style>';

				iframeContent.head.innerHTML += newStyle;

				document.querySelector('.recreations-section-intro .booking-block').classList.remove('is-hidden-search');
			}

		}
	}, 300);
}


// // Виджет поиска номеров на главной
// if ($('.home-section-intro #block-search').length > 0) {
// 	console.log('load frame');
// 	$('.home-section-intro #block-search').on("load", () => {
// 		console.log('iframe load');

// 		let iframeHead = $(".home-section-intro #block-search").contents().find("head");

// 		console.log(iframeHead);


// 		// let iframeCSS = "<style>.iframe-css{color: #e91905}</style>";
// 		// $(iframeHead).append(iframeCSS);
// 	});
// }




// ;(() => {
// 	// Выезжающий header
// 	const header = document.querySelector('.header')
// 	const introSection = document.querySelector('.intro')
// 	const blockMain = document.querySelector('.block-main')

// 	// let scroll = 0
// 	// window.onscroll = onScroll
// 	// function onScroll() {
// 	// 	const top = window.pageYOffset
// 	// 	if (scroll > top) {
// 	// 		header.style.top = 0
// 	// 	} else if (scroll < top) {
// 	// 		header.style.top = `-${
// 	// 			window.getComputedStyle(blockMain, null).height
// 	// 		}`
// 	// 	}
// 	// 	scroll = top
// 	// }
// 	// onScroll()

// 	document.addEventListener('scroll', () => {
// 		const scrollOffset = window.pageYOffset

// 		if (introSection) {
// 			if (scrollOffset > introSection.clientHeight) {
// 				header.classList.add('header-fixed')
// 				header.style.top = `-${
// 					window.getComputedStyle(blockMain, null).height
// 				}`
// 			}

// 			if (scrollOffset < introSection.clientHeight) {
// 				header.classList.remove('header-fixed')
// 				header.style.top = 0
// 			}
// 		}
// 	})
// })()


// ;(() => {
// 	//Mobile menu
// 	const openMenuBtn = document.querySelector('.burger')
// 	const closeMenuBtn = document.querySelector('.mobile__menu-btn-close')
// 	const mobileMenu = document.querySelector('.mobile__menu')

// 	openMenuBtn.addEventListener('click', () => {
// 		mobileMenu.style.display = 'grid'
// 		document.body.classList.add('open-mobile-menu')
// 	})

// 	closeMenuBtn.addEventListener('click', () => {
// 		mobileMenu.style.display = 'none'
// 		document.body.classList.remove('open-mobile-menu')
// 	})
// })()
