//---------------------------------
//	Sliders
//---------------------------------

export function Sliders($) {

	// Слайдер на первом экране главной
	(function () {
		const sliderIntro = $('.home-section-intro .slider-block')

		sliderIntro.on('init', function (event, slick) {
			$('.home-section-intro .main-block .slider-numbers .max').text(slick.slideCount)
		})

		setTimeout(() => {
			if ($('.home-section-intro .slider-block-wrap').length > 0) {
				$('.home-section-intro .slider-block-wrap').removeClass('is-hidden')
			}
		}, 1500)

		sliderIntro.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			// autoplay: true,
  			autoplaySpeed: 5000,
			fade: true,
			prevArrow: '.home-section-intro .main-block .slider-arrows .slick-prev',
			nextArrow: '.home-section-intro .main-block .slider-arrows .slick-next',
		})

		sliderIntro.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
			$('.home-section-intro .main-block .slider-numbers .current').text(nextSlide + 1)


			// Скрытие переключателей
			// if (currentSlide === 0 || (slick.slideCount - 1)) {
			// 	$('.home-section-intro .slider-arrows .slick-arrow').addClass('is-hidden');
			// }

			// C белым фоном
			if ($(slick.$slides[nextSlide]).hasClass('is-white-bg')) {
				$('.home-section-intro .main-block').addClass('is-slide-bg-white')
			} else {
				$('.home-section-intro .main-block').removeClass('is-slide-bg-white')
			}

			// Видеофон
			if ($(slick.$slides[nextSlide]).hasClass('video-bg')) {
				$('.home-section-intro .main-block').addClass('is-slider-numbers-bottom')
			} else {
				$('.home-section-intro .main-block').removeClass('is-slider-numbers-bottom')
			}

			if (nextSlide > 0) {
				$('.home-section-intro .main-block').addClass('is-slide-active')
			} else {
				setTimeout(() => {
					$('.home-section-intro .main-block').removeClass('is-slide-active');
				}, 400);
			}
		})
	})();

	// Слайдер в каждом номере из списка
	(function () {
		if ($('.catalog-card').length > 0) {
			$('.catalog-card').each(function () {
				const self = $(this).find('.catalog-card__image .slider')

				self.on('init', function (event, slick) {
					if (slick.slideCount === 1) {
						self.addClass('is-slide-1')
					}
				})

				self.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
					fade: true,
				})
			});
		}
	})();

	(function () {
		if($('.recreation-number-item .slider-win').length) {
			$('.recreation-number-item .slider-win').each((index) => {
				$(`.recreation-number-item .slider-win#slider-win-${index}`).slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					asNavFor: `.slider-controll#slider-controll-${index}`,
					arrows: false,
					dots: false,
					infinite: true,
					focusOnSelect: true,
					fade: false,
					cssEase: 'linear',
				});
			});
		}

		if($('.recreation-number-item .slider-controll').length) {
			$('.recreation-number-item .slider-controll').each((index) => {
				$(`.recreation-number-item .slider-controll#slider-controll-${index}`).slick({
					slidesToShow: 3,
					slidesToScroll: 1,
					arrows: false,
					vertical: false,
					dots: false,
					infinite: true,
					asNavFor: `.slider-win#slider-win-${index}`,
					focusOnSelect: true,
					centerMode: false,
					variableWidth: true
				});
			})
		}

		$().fancybox({
			selector : '.recreation-number-item .slider-win .slick-slide:not(.slick-cloned)',
			backFocus : false,
			afterShow : function( instance, current ) {
				current.opts.$orig.closest(".slick-initialized").slick('slickGoTo', parseInt(current.index), true);
			}
		});
	})();


	// Слайдер в каждом номере из списка
	(function () {
		if ($('.numbers-section-rooms').length > 0) {
			$('.numbers-section-rooms').each(function () {
				
				const self = $(this).find('.section-room .left-block-list .room-item .inner .slider')

				self.on('init', function (event, slick) {
					if (slick.slideCount === 1) {
						self.addClass('is-slide-1')
					}
				})

				self.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
					fade: true,
				})
			})
		}
	})();

	// Слайдер в каждом номере из списка
	(function () {
		if ($('.recreations-list__item').length > 0) {
			$('.recreations-list__item').each(function () {

				const self = $(this).find('.wrapper .left-block-list .room-item .inner .slider')
				self.on('init', function (event, slick) {
					if (slick.slideCount === 1) {
						self.addClass('is-slide-1')
					}
				})

				self.slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
					fade: true,
				})
			})
		}
	})();

	// Слайдер c акциями в списке номеров
	(function () {
		if ($('.numbers-section-stock-slider .stock-slider').length > 0) {
			const slider = $('.numbers-section-stock-slider .stock-slider');

			slider.on('init', function (event, slick) {
				if (slick.slideCount === 1) {
					self.addClass('is-slide-1')
				}
			})

			slider.slick({
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: false,
				arrows: true,
			})
		}
	})();

	(function () {
		let sliderConference = $('.conference-slider');
		sliderConference.slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			appendDots: $('.conference-slider-container .slick-dots-container'),
			// arrows: false,
			// autoplay: true,
  			// autoplaySpeed: 5000,
			fade: true,
			// prevArrow: '.conference-slider-container .slider-arrows .slick-prev',
			// nextArrow: '.conference-slider-container .slider-arrows .slick-next',
		})

		let sliderTab1 = $('.tab-slider-1');
		sliderTab1.slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			centerMode: true,
			// centerPadding: '200px',
			variableWidth: true,
			dots: false,
			appendDots: $('.tab-slider-1-container .slick-dots-1-container'),
			responsive: [
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						slidesToShow: 1,
					},
				},
			],
		})
		let slider2isInit = false;
		$('.conference-tabs-section #tab-2').on('click', function () {
			let sliderTab2 = $('.tab-slider-2');
			sliderTab2.slick({
				slidesToShow: 3,
				slidesToScroll: 1,
				centerMode: true,
				// centerPadding: '200px',
				variableWidth: true,
				dots: false,
				appendDots: $('.tab-slider-2-container .slick-dots-2-container'),
				responsive: [
					{
						breakpoint: 768,
						settings: {
							arrows: false,
							slidesToShow: 1,
						},
					},
				],
			})
		})
		let sliderVideoreview = $('.conference-videoreview-slider');
		let conferenceVideoreviewCurrent = $('.conference-videoreview-current');
		sliderVideoreview.slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			// centerMode: true,
			// centerPadding: '200px',
			// variableWidth: true,
			// dots: false,
			// appendDots: $('.tab-slider-container .slick-dots-container'),
			// infinite: true,
			// arrows: false,
			// autoplay: true,
  			// autoplaySpeed: 5000,
			// fade: true,
			prevArrow: '.conference-videoreview-section .slick-prev',
			nextArrow: '.conference-videoreview-section .slick-next',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						// arrows: false,
						slidesToShow: 1,
					},
				},
			],
		})
		sliderVideoreview.on('beforeChange', function(event, slick, currentSlide, nextSlide){
			console.log(slick.slideCount, nextSlide);
			if (!nextSlide) {
				conferenceVideoreviewCurrent.width('0%')
			} else {
				conferenceVideoreviewCurrent.width(100 / ((slick.slideCount - 1) / nextSlide) + '%')
			}
		});
	})();

	//Slider galary modal window
	// ;(() => {
	// 	$('.modal').on('shown.bs.modal', () => {
	// 		$('.galary-slider').slick('refresh')
	// 	})

	// 	$('.galary-slider').slick({
	// 		infinite: true,
	// 		slidesToShow: 1,
	// 		slidesToScroll: 1,
	// 		fade: true,
	// 		responsive: [
	// 			{
	// 				breakpoint: 768,
	// 				settings: {
	// 					arrows: false,
	// 				},
	// 			},
	// 		],
	// 	})
	// })()
}
