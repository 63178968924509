//---------------------------------
//	Window Scroll
//---------------------------------

export function WindowScroll($) {

	var lastScrollTop = 0;

	$(window).scroll(function(){
		var scrollTop = $(window).scrollTop();


		// Скрытие верхнего меню
		if ($('.header').length > 0) {
			let introHeight = 300;

			if ($('section.intro').length > 0) introHeight = $('section.intro').innerHeight();

			if (scrollTop > (introHeight / 2)) {
				$('.header').addClass('is-fixed');
				setTimeout(() => {
					$('.header').addClass('is-transition');
				}, 200);
			} else {
				$('.header').removeClass('is-fixed');
				setTimeout(() => {
					$('.header').removeClass('is-transition');
				}, 200);
			}

			if (scrollTop > introHeight) {
				$('.header').addClass('is-fixed-active');
			} else {
				$('.header').removeClass('is-fixed-active');
			}

			if (scrollTop > lastScrollTop) {
				$('.header').removeClass('is-menu-only');
			} else {
				if (scrollTop > ($('.header').outerHeight() + 50)) {
					$('.header').addClass('is-menu-only');
				} else {
					$('.header').removeClass('is-menu-only');
				}
			}
        }


		lastScrollTop = scrollTop;
	});

}
