//---------------------------------
//	Modals
//---------------------------------

import { helpers } from './helpers.js';

export function Modals($) {
	// $('#modalCallback').on('show.bs.modal', function (e){
	// 	console.log('modal open')
	// });
	
	// $('#modalCallback').on('hidden.bs.modal', function (e){
	// 	console.log('modal close')
	// });


	$('#modalMap').on('show.bs.modal', function (e){
		// Карта в футере
		if ($('#modalMap .modal-content .map').length > 0) {
			if ($('#modalMap .modal-content .map').children().length === 0) {
				helpers.yandexMapInit($('#modalMap .modal-content .map')[0], $('#modalMap .modal-content .map').data('map-marker'), $('#modalMap .modal-content .map').data('map-zoom'));
			}
		}
	});

}
