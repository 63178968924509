//---------------------------------
//	Common
//---------------------------------

import { helpers } from './helpers.js';

export function Common($) {

    // Lazy load image
    const lazyLoadAll = new LazyLoad({
        elements_selector: '.lazy'
    });


    
    // WOW js
    new WOW().init();

    // Куки
    // console.log($.cookie('_ga'))

    // Переход по якорям
    $('.scroll-to').click(function(e){
        const el = $(this).attr('href');

        if (window.location.href === globalParams.site_url + '/') {
            e.preventDefault();

            if (document.querySelector(el) !== null) {
                $('html, body').animate({
                    scrollTop: $(el).offset().top
                }, 500);
            }
        } else {
            window.location.href = globalParams.site_url + '/' + el;
        }
        
        return false;
    });

    // Baron scrollbar
    // $('.baron__clipper').baron({
    //     scroller: '.baron__clipper > .scroller',
    //     track: '.baron__clipper > .scroller__track',
    //     bar: '.baron__clipper > .scroller__track .scroller__bar',
    // });


    // Замена фонов в мобильной версии
    $('.has-bg-mobile').each(function(){
        const self = $(this);

        if (window.innerWidth <= 768) {
            if (self.data('bg-mobile') !== undefined && $(this).data('bg-mobile') !== '') {
                self.css('background-image', function () {
                    let bg = ('url(' + $(this).data('bg-mobile') + ')');
                    return bg;
                });
            }
        }
    });



    // Переключение изображений при наведении
    $('.images-hover-change').each(function(){
        const self = $(this);

        self.find('.list .line').on('mouseover', function(){
            self.find('.dots .dot').removeClass('is-active');
            self.find('.dots .dot[data-id="' + $(this).data('id') + '"]').addClass('is-active');
            self.find('.bg').css('background-image', 'url(' + $(this).data('src') + ')')
        });
    });


    // Поиск по тегам в статьях
    $('.actual-section-relax .section-articles .tags-list-item').click(function(){
        const tagName = $(this).data('name');

        function loadPost (tag) {
            $.ajax({
                type: "POST",
                url: globalParams.ajax_url,
                data: {
                    action: 'load_posts_by_tag',
                    tag: tag
                },
                beforeSend: function(res) {
                    $('.actual-section-relax .section-articles .articles-block').addClass('is-loading');
                },
                success: function(res) {
                    $('.actual-section-relax .section-articles .articles-block').removeClass('is-loading');
                    $('.actual-section-relax .section-articles .articles-block .inner').html(res);
                },
                error: function(e) {
                    console.log(e)
                }
            });
        }

        if (!$(this).hasClass('--is-active')) {
            $('.actual-section-relax .section-articles .tags-list-item').removeClass('--is-active');
            $(this).addClass('--is-active');
            loadPost(tagName);
        } else {
            $(this).removeClass('--is-active');
            loadPost('all');
        }
    });


    // Есть чем заняться, переключение табов
    // $('.home-section-entertainment .entertainment__tab').click(function(){
    //     const selectType = $(this).data('type'); 

    //     $('.home-section-entertainment .entertainment__tab').removeClass('is-active');
    //     $(this).addClass('is-active');

    //     if (selectType === 'all') {
    //         $('.home-section-entertainment .entertainment__card').removeClass('is-hidden');
    //     } else {
    //         $('.home-section-entertainment .entertainment__card').addClass('is-hidden');
    //         $('.home-section-entertainment .entertainment__card-wrapper .entertainment__card').each(function(){
    //             const card = $(this),
    //                 types = card.data('type');

    //             card.data('type').split(',').forEach(cardType => {
    //                 if (cardType.includes(selectType)) {
    //                     card.removeClass('is-hidden')
    //                 }
    //             });
    //         });
    //     }
    // });

    // Табы каталога
    if($('.recreations-tags').length) {
        $('.recreations-tags.tags .tags-item').click(function(){
            const tagName = $(this).data('term');
            console.log(tagName)

            function loadPost (tag) {
                $.ajax({
                    type: "POST",
                    url: globalParams.ajax_url,
                    data: {
                        action: 'load_catalog_by_tag',
                        tag: tag
                    },
                    beforeSend: function(res) {
                        $('.recreations-list .recreations-list__items').addClass('is-loading');
                    },
                    success: function(res) {
                        $('.recreations-list .recreations-list__items').removeClass('is-loading');
                        $('.recreations-list .recreations-list__items').html(res);

                        // Переключение изображений при наведении
                        $('.images-hover-change').each(function(){
                            const self = $(this);

                            self.find('.list .line').on('mouseover', function(){
                                self.find('.dots .dot').removeClass('is-active');
                                self.find('.dots .dot[data-id="' + $(this).data('id') + '"]').addClass('is-active');
                                self.find('.bg').css('background-image', 'url(' + $(this).data('src') + ')')
                            });
                        });
                        if($('.recreations-list .recreations-list__items .recreations-list__item').length > 6) {
                            $('.recreations-list .recreations-list__items .recreations-list__item').each(function (index){
                                if(index + 1 > 6) {
                                    $(this).addClass('is-hidden');
                                }
                            })
                            $('.recreations-list .bottom').removeClass('is-hidden')
                        } else {
                            $('.recreations-list .bottom').addClass('is-hidden')
                        }
                        if(!res) {
                            $('.recreations-list .empty').removeClass('is-hidden');
                        } else {
                            $('.recreations-list .empty').addClass('is-hidden');
                        }

                    },
                    error: function(e) {
                        console.log(e)
                    }
                });
            }

            $('.recreations-tags.tags .tags-item').removeClass('tags-item-active');
            $(this).addClass('tags-item-active');
            loadPost(tagName);

            // const selectType = $(this).data('term');
            //
            // $('.recreations-tags.tags .tags-item').removeClass('tags-item-active');
            // $(this).addClass('tags-item-active');
            //
            // if (selectType === 'all') {
            //     $('.recreations-list__items .recreations-list__item').removeClass('is-hidden');
            // } else {
            //     $('.recreations-list__items .recreations-list__item').addClass('is-hidden');
            //     $('.recreations-list__items .recreations-list__item').each(function(){
            //         const card = $(this),
            //             types = card.data('terms').toString();
            //         types.split(',').forEach(cardType => {
            //             if (cardType.includes(selectType)) {
            //                 card.removeClass('is-hidden')
            //             }
            //         });
            //     });
            // }
        });
    }

    // Кнопка "Показать ещё" в каталоге

    if($('.recreations-list .bottom.more').length) {
        $('.recreations-list .bottom.more').click(() => {
            let itemsList = document.querySelectorAll('.recreations-list .recreations-list__items .recreations-list__item');
            let countForShow = 6;
            if(itemsList.length) {
                itemsList = [...itemsList];
                let isHiddenItems = itemsList.filter((item) => item.classList.contains('is-hidden'))
                if(isHiddenItems.length) {
                    isHiddenItems.forEach((item, index) => {
                        if(index + 1 <= countForShow) {
                            $(item).removeClass('is-hidden')
                        }
                    })
                }
                console.log(document.querySelectorAll('.recreations-list .recreations-list__items .recreations-list__item'))
                if(![...document.querySelectorAll('.recreations-list .recreations-list__items .recreations-list__item')].filter((item) => item.classList.contains('is-hidden')).length) {
                    $('.recreations-list .bottom.more').addClass('is-hidden')
                }
            }
        })
    }


    // Карта на странице контактов
    if ($('.contacts-section-map .map').length > 0) {
        helpers.yandexMapInit($('.contacts-section-map .map')[0], $('.contacts-section-map .map').data('map-marker'), $('.contacts-section-map .map').data('map-zoom'));
    }



    // Перемещение активного элипса в меню
    (function(){
        const currentItem = $('.header .block-menu .main-menu > .menu-item.current-menu-item > a'),
            elipse = $('.header .block-menu .active-elipse');

        if (currentItem.length > 0) {
            let offset = (currentItem.innerWidth() - elipse.innerWidth()) / 2;

            elipse.css('transform', 'translateX(' + (currentItem.parent().position().left + offset) + 'px)');
        } else {
            elipse.addClass('is-hidden');
        }

        $('.header .block-menu .main-menu > .menu-item > a').on('mouseenter', function(){
            let offset = ($(this).innerWidth() - elipse.innerWidth()) / 2;

            elipse.css('transform', 'translateX(' + ($(this).parent().position().left + offset) + 'px)');
        });
            
    })();

    // Скрытие не помещающихся ссылок в header menu
    (function(){
		if (window.innerWidth >= 768) {
			let menuUlWidth = $('.header .block-menu .main-menu').width(),
				menuLiWidth = 0;

			$('.header .block-menu .main-menu > li:not(.more-links)').each(function () {
				const self = $(this)

				menuLiWidth += self.width() + 25

				if (menuLiWidth > menuUlWidth) {
					if ($('.main-menu > li.more-links').length === 0) {
						$('.header .block-menu .main-menu').append(
							'<li class="more-links menu-item menu-item-has-children"><ul class="sub-menu"></ul></li>',
						)
					}

					$('.header .block-menu .main-menu > li.more-links ul').append(self)

					self.addClass('is-hidden')
				}
			});

			setTimeout(() => {
				$('.header .block-menu').removeClass('is-hidden');
			}, 500);
		}

		// $('.header .wrap nav.top-menu').removeClass('is-hidden')
	})();


    // AJAX подгрузка работ в портфолио
    $('.actual-section-relax .section-articles .articles-block .loadmore .btn').click(function(){
        var self = $(this),
            data = {
                'action': 'articles_loadmore',
                'query': true_posts,
                'page' : current_page
            };

        self.text('Загрузка...');

        $.ajax({
            url: globalParams.ajax_url,
            data: data,
            type: 'POST',
            success:function(data){
                if( data ) { 
                    $('.actual-section-relax .section-articles .articles-block .inner').append(data);
                    self.text('Показать ещё');
                    current_page++;
                    if (current_page == max_pages) {
                        self.parent().remove();
                    }
                } else {
                    self.parent().remove();
                }
            }
        });
    });

    // Вопросы-ответы
    (function(){
        $('.question__crisper .question__item').each(function(){
            const self = $(this);
            
            self.find('.category-toggle').click(function(){
                if (!self.hasClass('question__item_active')) {
                    self.find('.question__item-answer').eq(0).slideDown(200, function(){
                        self.addClass('question__item_active');
                    })
                } else {
                    self.find('.question__item-answer').eq(0).slideUp(200, function(){
                        self.removeClass('question__item_active');
                        // self.find('.question__item-answer .question__item').eq(0).addClass('question__item_active');
                        // self.find('.question__item-answer .question__item').eq(0).find('.question__item-answer').slideDown(200);
                    })
                }
            });

            self.find('.question__item-answer .question__item-additionally .question__item .asnwer-toggle').click(function(){
                if (!$(this).parent().hasClass('question__item_active')) {
                    $(this).parent().find('.question__item-answer').slideDown(200, function(){
                        $(this).parent().addClass('question__item_active');
                    })
                } else {
                    $(this).parent().find('.question__item-answer').slideUp(200, function(){
                        $(this).parent().removeClass('question__item_active');
                    })
                }
            });
        });

    })();

    // Открытие/закрытие гамбургеры
    (function(){
        if ($('.header .burger').length > 0) {
            $('.header .burger').click(function(){
                if (!$('.header').hasClass('is-mobile-menu-open')) {
                    $('body').addClass('is-overflow');
                    $('.header').addClass('is-mobile-menu-open');
                } else {
                    $('body').removeClass('is-overflow');
                    $('.header').removeClass('is-mobile-menu-open');
                }
            });
        }
    })();


    // Блок со скролом на главной
    (function(){
        if ($('.section-do-have').length > 0) {
            var $container = $('.section-do-have');
            var $inner = $('.section-do-have .section-do-have-scroller');

            var cHeight = $container.height();
            var cWidth = $container.width();
            var iHeight = $inner.height();
            var iWidth = $inner.width();

            if (window.innerWidth > 768) {
                var panner = new helpers.moveMouseScrollBlock($, $container, iWidth, iHeight);
            }
        }
    })();

    // Паралаксы
    (function(){
        // Секция интро
        if (document.querySelector('.home-section-intro.intro .section-backstage-wrap') !== null) {
            var scene = document.querySelector('.home-section-intro.intro .section-backstage-wrap .list');

            var parallaxInstance = new Parallax(scene, {
                scalarX: 1,
                scalarY: 1,
            });
        }

        // Преимущества
        if (document.querySelector('.home-section-benefits .section-backstage-wrap') !== null) {
            var scene = document.querySelector('.home-section-benefits .section-backstage-wrap .list');

            var parallaxInstance = new Parallax(scene, {
                scalarX: 1,
                scalarY: 1,
            });
        }

        // Футер
        if (document.querySelector('.footer .section-backstage-wrap') !== null) {
            var scene = document.querySelector('.footer .section-backstage-wrap .list');

            var parallaxInstance = new Parallax(scene, {
                scalarX: 1,
                scalarY: 1,
            });
        }


        // Animation scroll
        lax.init();
        lax.addDriver(
            "scrollY",
            function () {
                return document.documentElement.scrollTop;
            },
            { frameStep: 1 }
        );
        if (window.innerWidth > 991) {
            lax.addElements(".stag-party-section-event .icon.icon-1", {
                scrollY: {
                    translateY: [["elInY", "elOutY"], [0, -100]]
                }
            });
            lax.addElements(".stag-party-section-event .icon.icon-2", {
                scrollY: {
                    translateY: [["elInY", "elOutY"], [0, -50]]
                }
            });
            lax.addElements(".stag-party-section-event .icon.icon-3", {
                scrollY: {
                    translateX: [["elInY", "elOutY"], [0, -100]]
                }
            });
            lax.addElements(".stag-party-section-event .icon.icon-4", {
                scrollY: {
                    translateY: [["elInY", "elOutY"], [0, 120]]
                }
            });
            lax.addElements(".stag-party-section-event .icon.icon-5", {
                scrollY: {
                    translateY: [["elInY", "elOutY"], [0, 100]]
                }
            });
            lax.addElements(".stag-party-section-event .icon.icon-6", {
                scrollY: {
                    translateY: [["elInY", "elOutY"], [0, 100]]
                }
            });
            lax.addElements(".stag-party-section-event .icon.icon-7", {
                scrollY: {
                    translateY: [["elInY", "elOutY"], [0, -100]]
                }
            });
            lax.addElements(".stag-party-section-event .icon.icon-8", {
                scrollY: {
                    translateY: [["elInY", "elOutY"], [0, 100]]
                }
            });
            lax.addElements(".stag-party-section-event .icon.icon-9", {
                scrollY: {
                    translateY: [["elInY", "elOutY"], [0, -100]]
                }
            });
            lax.addElements(".stag-party-section-event .icon.icon-10", {
                scrollY: {
                    translateY: [["elInY", "elOutY"], [0, 100]]
                }
            });
        }

    })();


    // Лайк в статьях 
    $('.article-section-info .section-article .author-like').click(function(){
        const self = $(this), 
            id = self.closest('.author-block-like').data('id'),
            count = self.closest('.author-block-like').find('.author-likes span');

        $.ajax({
            type: "POST",
            url: globalParams.ajax_url,
            data: {
                'action': 'post_like',
                'id': id,
                'count': Number(count.text()),
            },
            beforeSend: function() {
                self.addClass('is-loading');
            },
            success: function(res) {
                if (res) {
                    self.removeClass('is-loading');
                    self.addClass('is-active');
                    count.text(res);
                }
            },
            error: function(e) {
                console.log(e)
            }
        });
    });

    // Секция - Внутри имеется
    (function(){



        if ($('.numbers-section-rooms').length > 0) {
            $('.numbers-section-rooms').each(function(i){
                const self = $(this);
                let count = 8;

                if (window.innerWidth > 991 && window.innerWidth < 1600) {
                    count = 9;
                }

                if (self.find('.section-room .right-block-second-inner-item').length > 0) {
                    self.find('.section-room .right-block-second-inner-item').each(function(i){
                        if (count < self.find('.section-room .right-block-second-inner-item').length) {
                            self.find('.section-room .right-block-second-show-more').removeClass('is-hidden');
                        }

                        if (i < count) {
                            $(this).removeClass('is-hidden');
                        }
                    });
                }
                

                self.find('.section-room .right-block-second-show-more').click(function () {
                    $(this).remove();
                    self.find('.section-room .right-block-second-inner-item').removeClass('is-hidden');
                });
            });
        }

       
    })();


    // Хлебыне крочки, дополнение
    if ($('.bread-crumbs').length > 0) {
        const lastText = $('.bread-crumbs > span:last-child .post').text();

        // Добавить текст мероприятия
        if (lastText === 'День рождения' ||
            lastText === 'Девичник' ||
            lastText === 'Мальчишник') {

            $('<span property="itemListElement" typeof="ListItem"><span class="post post-page">Мероприятия</span></span><span class="separator"></span>').insertBefore($('.bread-crumbs > span:last-child'))
        }
        
    }



    

}



  
// let speedAnimation = 400;
// let targetId = document.getElementById("target");

// let slideBtnClick = (id, sl) => document.getElementById(id).addEventListener('click', () => sl(targetId, speedAnimation));

// slideBtnClick('triggerUp', slideUp);
// slideBtnClick('triggerDown', slideDown);
// slideBtnClick('triggerToggle', slideToggle);


