//---------------------------------
//	Window Resize
//---------------------------------

export function WindowResize($) {

	$(window).resize(function(){

	});

}
