//---------------------------------
//	Helpers
//---------------------------------

export const helpers = {
    getUrlParameter: function(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    },
    yandexMapInit: function(mapEl, mapMarker, mapZoom) {
        if (typeof ymaps !== 'undefined') {
            ymaps.ready(init);
        }

        function init(){
            // Создание карты.
            if (mapEl !== undefined) {
                if (mapMarker !== undefined) {
                    const myMap = new ymaps.Map(mapEl, {
                        center: mapMarker.coords, 
                        zoom: mapZoom !== undefined ? mapZoom : 11,
                        type: 'yandex#map',
                        controls: []
                    });

                    const controlOptions = {
                        float: 'none',
                        position: {
                            left: 20,
                            top: 20
                        }
                    };

                    myMap.controls.add('zoomControl', controlOptions);
                    myMap.behaviors.disable('scrollZoom');


                    var fullscreenControl = new ymaps.control.FullscreenControl({
                        options: {
                            position: {
                                top: 20,
                                right: 20
                            }
                        }
                    });
                    // myMap.controls.add(fullscreenControl);

                    let iconSize = [50, 50],
                        iconOffset = [-25, -50];

                    myMap.geoObjects
                        .add(new ymaps.Placemark(mapMarker.coords, {
                            balloonContent: mapMarker.title
                        }, {
                            iconLayout: 'default#image',
                            iconImageHref: globalParams.theme_url + '/assets/images/svg/map-marker.svg',
                            iconImageSize: iconSize,
                            iconImageOffset: iconOffset
                        }))

                }
            }
        }
    },
    slideUp: function(target, duration = 300) {
        target.style.transitionProperty = 'height, margin, padding';
        target.style.transitionDuration = duration + 'ms';
        target.style.boxSizing = 'border-box';
        target.style.height = target.offsetHeight + 'px';
        target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        window.setTimeout( () => {
            target.style.display = 'none';
            target.style.removeProperty('height');
            target.style.removeProperty('padding-top');
            target.style.removeProperty('padding-bottom');
            target.style.removeProperty('margin-top');
            target.style.removeProperty('margin-bottom');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    },
    slideDown: function(target, duration = 300) {
        target.style.removeProperty('display');
        let display = window.getComputedStyle(target).display;

        if (display === 'none') display = 'block';

        target.style.display = display;
        let height = target.offsetHeight;
        target.style.overflow = 'hidden';
        target.style.height = 0;
        target.style.paddingTop = 0;
        target.style.paddingBottom = 0;
        target.style.marginTop = 0;
        target.style.marginBottom = 0;
        target.offsetHeight;
        target.style.boxSizing = 'border-box';
        target.style.transitionProperty = "height, margin, padding";
        target.style.transitionDuration = duration + 'ms';
        target.style.height = height + 'px';
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        window.setTimeout( () => {
            target.style.removeProperty('height');
            target.style.removeProperty('overflow');
            target.style.removeProperty('transition-duration');
            target.style.removeProperty('transition-property');
        }, duration);
    },
    slideToggle: function(target, duration = 300) {
        if (window.getComputedStyle(target).display === 'none') {
            return this.slideDown(target, duration);
        } else {
            return this.slideUp(target, duration);
        }
    },
    moveMouseScrollBlock: function($, $container, iWidth, iHeight) {
        var self = this;
    
        var cWidth = $container.width(),
            cHeight = $container.height();

        this.getContainerWidth = function() {
            return cWidth;
        };
    
        this.getContainerHeight = function() {
            return cHeight;
        };
    
        this.getPan = function() {
            return {
                x: $container.scrollLeft(),
                y: $container.scrollTop()
            };
        };
    
        this.pan = function(deltaX, deltaY) {
            var current = self.getPan();
            self.panTo(
                current.x + deltaX,
                current.y + deltaY
            );
        };
    
        this.panTo = function(x, y) {
            $container.scrollLeft(x);
            $container.scrollTop(y);
        };
    
        this.centerImage = function() {
            self.panTo(
                (iWidth - cWidth) * 0.5,
                (iHeight - cHeight) * 0.5
            );
        };
    
        // mouse panning
    
        var isPanning = false,
            previousX,
            previousY;
    
        $container.on('mousedown', function(e) {
            previousX = e.clientX;
            previousY = e.clientY;
            isPanning = true;
        });
    
        $container.on('touchstart', function(e) {
            if (e.originalEvent.touches.length !== 1) {
                return;
            }
            previousX = e.originalEvent.touches[0].clientX;
            previousY = e.originalEvent.touches[0].clientY;
            isPanning = true;
        });

        // $container.on('pointerdown', function(e) {
        //     if (!isPanning) {
        //         return;
        //     }

        //     console.log('pointerdown');
        //     console.log(e);
        // });
    
        $(document).on('mouseup touchend touchcancel', function() {
            isPanning = false;
        });
    
        $container.on('mouseleave touchend touchcancel', function() {
            isPanning = false;
        });
    
        $container.on('mousemove', function (e) {
            if (!isPanning) {
                return;
            }
            e.preventDefault();
            self.pan(previousX - e.clientX, previousY - e.clientY);
            previousX = e.clientX;
            previousY = e.clientY;
        });
    
        $container.on('touchmove', function (e) {
            if (!isPanning || e.originalEvent.touches.length !== 1) {
                return;
            }
            e.preventDefault();
            var clientX = e.originalEvent.touches[0].clientX,
                clientY = e.originalEvent.touches[0].clientY;
            self.pan(previousX - clientX, previousY - clientY);
            previousX = clientX;
            previousY = clientY;

            // console.log(e);

            // if (e.shiftKey) {
                // console.log(e);
                // var dist = Math.hypot(
                //     e.touches[0].pageX - e.touches[1].pageX,
                //     e.touches[0].pageY - e.touches[1].pageY);

                // console.log(dist);
                // console.log('---------');
            // }
            
        });
    
    }
}
